//GLOBAL
html {
  height: 100%;
   @include box-sizing;
   font-size: 100%;
   overflow: hidden; //Patch for board fixed positionning
}

body{
	@include box-sizing;
	color: $black-off;
	font-family: $roboto;
	font-weight: 400;
	background-color: $white-off;
	font-size: $base-font-size;
	position: relative;
	margin: 0;
	line-height: 1.4rem;
	height: 100%;
}

.main-container{
	//position: relative;
}

.container {
	@include max-width(1200px);
}

.content-header {
	+ .container {
		padding-top: $gutter*2;
	}
}

h1 {
	font-size: $h1;
	font-family: $opensans;
	margin-bottom: $gutter*2;
	font-weight: $bold;
	line-height: normal;
}

h2 {
	font-size: $h2;
	font-family: $opensans;
	margin-bottom: $gutter*2;
	font-weight: $bold;
	line-height: normal;
}

[class*="fa-"] {
	margin: 0 auto;
}