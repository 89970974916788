//COLORS
$primary: #1D1D1D;
$grey-light: #CCCCCC;
$grey-medium: #82846a;
$white: #FFFFFF;
$grey-eee: #EEEEEE;
$black-off: #1D1D1D;
$white-off: #E6E6E6;
$black: #000000;
$blue: #66afe9;

$box-shadow-dark: rgba(115,115,155,0.5);
$box-shadow-light: rgba(115,115,155,0.2);

//FONTS
$opensans: 'Open Sans Condensed', sans-serif;
$roboto: 'Roboto', sans-serif;
$h1: 1.8rem;
$h2: 1.4rem;
$base-font-size: 1rem;
$small-font-size: 0.8rem;
$mentions-font-size: 0.65rem;
$bold: 700;
$regular: 400;

//MARGINS & PADDINGS
$gutter: 0.6rem;


//RADIUS
$radius: 8px;
$small-radius: 4px;


//BREAKPOINTS
$lg: 1200px;
$md: 1024px;
$sm: 768px;
$xs: 480px;


//MIXINS
$baseFontSize : 16;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}


/// ***Content container
@mixin max-width($maxwidth, $margin: true){
	width: 100%;
	max-width: $maxwidth;
	margin: if($margin, 0 auto, 0);
}

/// ***Multiple box-shadows
@mixin multiple-box-shadow($params) {
    @include css3-prefix('box-shadow', $params);
}

/// ***Align vertically
@mixin align-vertically(){
    position: absolute;
    top: 50%;
    left: 0;
    @include css3-prefix($property:'transform', $value:translateY(-50%));
}

/// ***Align horizontally
@mixin align-horizontally(){
    position: absolute;
    top: 0;
    left: 50%;
    @include css3-prefix($property:'transform', $value:translateX(-50%));
}