// HEADER styles
[role="header"] {
    position: relative;
    z-index: 1;
}

.log {
    position: absolute;
    top: 60px;
    right: 30px;
    display: flex;
    flex-direction: column;
    @include transition(all 0.3s ease-in-out);
    @include css3-prefix($property:'transform', $value:translateX(150%));
    width: 60px;

    &.opened {
        @include css3-prefix($property:'transform', $value:translateX(50%));
    }
}

.actionsTogglerContainer {
    position: absolute;
    right: 0;
    width: 30px;
}


[class*="logAction"] {
    background-color: $white;
    padding: $gutter;
    margin-top: $gutter;
    text-align: center;
    border-radius: $radius 0 0 $radius;
    @include box-shadow($x: 0, $y: 3px, $blur: 8px, $color: $box-shadow-light);
    @include transition(all 0.3s ease-in-out);

    &:hover,
    &:focus {
        @include box-shadow($x: 0, $y: 0px, $blur: 8px, $color: $box-shadow-light);
    }

    [class*="bt-icon"] {
        border: 0;

        &:focus {
            box-shadow: none;
        }
    }

    &.logActionToggler {
        cursor: pointer;

        .fa-angle-right {
            display: none;
        }
        .fa-angle-left {
            display: block;
        }

        &.opened {
            .fa-angle-left {
                display: none;
            }
            .fa-angle-right {
                display: block;
            }
        }   
    }
}

.content-header{
    background-color: $black-off;
    color: $white-off;
    padding: #{$gutter*2} 0;
    width: 100%;
    @include box-shadow($x: 0, $y: 5px, $blur: 8px, $color: $box-shadow-dark);

    .container {
        display: flex;
        align-items: center;
    }

    .logo{
        max-width: 120px;
        margin-right: $gutter*2;
    }

    h1{
        margin: 0;
    }
}