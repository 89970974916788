// TABLES styles

.tables {
    display: flex;
    flex-wrap: wrap;

    .table {
        flex: 1 1 20%;
        max-width: 20%;
        padding: $gutter;
        position: relative;
        min-height: 150px;
        
        .container {
            @include box-shadow($x: 0, $y: 0, $blur: 8px, $color: $box-shadow-light);
            background-color: $white-off;
            box-sizing: border-box;
            color: $black-off;
            padding: $gutter;
            height: 100%;
            transition: all linear 0.3s;
            cursor: url("/static/icons/right-click.png"), pointer;
            
            &:hover,
            &:focus,
            &:active {
                background-color: $grey-eee;
                @include box-shadow($x: 0, $y: 5px, $blur: 8px, $color: $box-shadow-dark);
            }
        }

        h2{
            margin-bottom: $gutter;
            text-align: center;
        }

        .label {
            font-weight: bold;
        }

        &.empty-table {
            .container {
                cursor: default;
                padding: 0;
            }

            .cta-primary {
                font-size: $h2;
                height: 100%;
                width: 100%;
            }
        }

    }
}

.explain {
    padding: $gutter;
}
