// LOGIN styles
.loginput {
    background-color: $black-off;
    color: $white-off;
    padding: $gutter*2;
    width: 100%;
    @include align-vertically();
    @include box-shadow($x: 0, $y: 5px, $blur: 8px, $color: $box-shadow-dark);

    .container {
        @include flexbox();
        @include justify-content(center);
    }

    .right-col,
    .left-col {
        padding: $gutter;
    }
    .right-col {
        padding-left: $gutter*3;
    }
}