//MAIN.CSS
/* .loginput {
    width: 300px;
    position: absolute;
    left: 40%;
    top: 40%;
} */

/* .log {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 40px;
}

.logAction {
    width: 50px;
    height: 20px;
} */




.active_table {
    width: 100%;
    height: 100%;
    background-color: lightgrey;
    position: absolute;
    top: 0px;
    left: 0px;
}


#deck_creation {
    width: 100%;
    background: lightgrey;
    height: 100%;
    z-index: 1;
}

.deck_creation_color {
    width: 170px;
    position: relative;
    float: left;
}

.deck_creation_color_group {
    width: 170px;
    height: 400px;
    position: relative;
    float: left;
}

.deck_creation_card {
    height: 212px;
    width: 150px;
    position: absolute;
}

#deck_creation_discarded {
    position: absolute;
    right: 50px;
    top: 25px;
}

#deck_creation_selected {
    position: absolute;
    right: 50px;
    top: 425px;
}

#deck_creation_available {
    position: absolute;
    right: 50px;
    top: 25px;
}

#bigCard {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 1440px;
    height: 960px;
    background-color: white;
    display: none;
    z-index: 42;
}

#bigCardImg {
    height: 640px;
    width: 480px;
}

#closeBigImg {
    position: relative;
    top: 5px;
    left: 475px;
    z-index: 43;
}

.bigCardCarousel img {
    max-width: 480px;
    max-height: 680px;
}

.bigCardCarousel{
    max-width: 480px;
    max-height: 680px;
    position: relative;
    left: 50px;
}

.bigCardCarouselClose{
    position: absolute;
}

.deck_creation_card_btn {
    display: none;
    position: absolute;
}

.deck_creation_group_btn {
    position: absolute;
}

.manaCard {
    width: 160px;
    height: 250px;
    float: left;
    position: relative;
}

.manaCardNumber {
    position: relative;
    bottom: 60px;
    left: 110px;
    width: 50px;
    font-size: 32px;
}

.arrow_up {
    position: absolute;
    bottom: 75px;
    left: 5px;
    width: 48px;
}

.arrow_down {
    position: absolute;
    bottom: 45px;
    left: 5px;
    width: 48px;
}

.manas {
    width: 100%;
    height: 250px;
}

.mana_deck {
    width: 100%;
    height: 500px;
}

.mana_deck_color {
    height: 500px;
    width: 1px;
    position: relative;
    float: left;
}

.mana_deck_color_type {
    height: 250px;
    width: 1px;
    position: relative;
    float: left;
}

.mana_stats {
  height: 250px;

  width: 400px;
  position: relative;
  float: left; }

.cards_mana_stats {
  height: 250px;
  width: 100px;
  position: relative;
  float: left; }

.cards_mana_curve {
  height: 200px;
  width: 200px;
  position: relative;
  float: left; }

.chargeCounter {
  position: relative;
  height: 0px;
  top: 20px;
  left: 25px;
  z-index: 1;
  color: white;
  font-size: 32px; }

.counter {
  position: relative;
  height: 0px;
  top: 75px;
  left: 25px;
  z-index: 1;
  color: black;
  font-size: 32px; }



//GAME.CSS
.small_card {
    height: 106px;
    width: 75px;
    position: absolute;
}

.big_card {
    height: 288px;
    width: 216px;
}

#board
{
    width: 100%;
    height: 100%;
    margin:0 auto;
    overflow:hidden;
    float:left;
    clear: both;
    background-color: lightgrey;
    position: fixed;
    top: 0px;
    left: 0px;
}

#player_name {
}

#player_life {
}

#player_poison {
}

.user_info {
    width: 10%;
    height: 106px;
    background-color: white;
    background-image: url("/static/backgrounds/life.jpg");
    float: left;
    z-index: 2;
    color: black;
    position: relative;
}

#opponent_battlefield {
    top: 15px;
}

#player_battlefield {
    top: 15px;
}

.user_land {
    width: 72%;
    height: 106px;
    position: relative;
    float: left;
    background-color: white;
    background-image: url("/static/backgrounds/land.jpg");
    background-size: cover;
    z-index: 50;
}

.user_library {
    width: 6%;
    height: 106px;
    position: relative;
    float: left;
    background-color: white;
    background-image: url("/static/backgrounds/library.jpg");
    background-size: cover;
    z-index: 50;
}

.user_graveyard_cards {
    position: absolute;
}

.user_graveyard {
    width: 6%;
    height: 106px;
    position: relative;
    z-index: 2;
    float: left;
    background-color: white;
    background-image: url("/static/backgrounds/graveyard.jpg");
    background-size: cover;
}

.user_exile {
    width: 6%;
    height: 106px;
    position: relative;
    z-index: 2;
    float: left;
    background-color: white;
    background-image: url("/static/backgrounds/exile.jpg");
    background-size: cover;
}

.user_exile:after {
    content: " ";
    z-index: 1;
    display: block;
    position: absolute;
    width: 76px;
    height: 106px;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

.user_battlefield {
    width: 100%;
    height: 320px;
    background-image: url("/static/backgrounds/battlefield.jpg");
    float: left;
    position: relative;
    background-size: cover;
}


.on_board_card_small {
    margin: auto;
    position: relative;
  float: left;
  width: 76px;
  height: 106px; 
}

.on_board_card_big {
    position: relative;
}

.on_board_card_opponent_hand {
    bottom: 95px;
    position: relative;
}

#player_hand {
    width: 100%;
    height: 15px;
    background-color: grey;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background-image: url("/static/backgrounds/hand.jpg");
    background-size: cover;
}

#opponent_hand {
    width: 100%;
    height: 15px;
    background-color: grey;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background-image: url("/static/backgrounds/hand.jpg");
    background-size: cover;
}

#opponent_land {
    top: 15px;
}

#opponent_library {
    top: 15px;
}

#opponent_graveyard {
    top: 15px;
}

#opponent_exile {
    top: 15px;
}

#opponent_info {
    top: 15px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

#player_land {
    top: 15px;
}

#player_library {
    top: 15px;
}

#player_graveyard {
    top: 15px;
}

#player_exile {
    top: 15px;
}

#player_info {
    top: 15px;
    bottom: 0px;
}

.user_creatures{
    width: 70%;
    height: 320px;
    background-image: url("/static/backgrounds/battlefield.jpg");
    float: left;
    position: relative;
}

.user_planeswalkers {
    width: 5%;
    height: 320px;
    background-image: url("/static/backgrounds/battlefield.jpg");
    float: left;
    position: relative;
}

.user_artifacts {
    width: 15%;
    height: 320px;
    background-image: url("/static/backgrounds/battlefield.jpg");
    float: left;
    position: relative;
}

.user_others {
    width: 10%;
    height: 320px;
    background-image: url("/static/backgrounds/battlefield.jpg");
    float: left;
    position: relative;
}

#player_creatures {
    
}

#opponent_creatures {
}

#game_player {
    height: 50%;
}

#game_opponent {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    height: 50%;
}

.untap {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.tap {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bindCard {
    position: absolute;
}

.add_counter {
    width: 20px;
    height: 20px;
}

.remove_counter {
    width: 20px;
    height: 20px;
}

#browse {
    width: 100%;
    height: 100%;
    display: none;
}

#closeBrowseButton {
    position: absolute;
}

.libraryCardCarousel {
    height: 340px;
    width: 1440px;
}

