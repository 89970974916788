// MAIN file => imports only

// Global styles & configs
@import 'css3-mixins';
@import 'config';
@import 'reset';
@import 'global';

@import 'legacy'; //to be removed

//UI
@import 'ui/form';
@import 'ui/buttons';

// Modules
@import 'modules/header';
@import 'modules/login';
@import 'modules/tables';
