// FORM STYLES
textarea, 
select, 
input[type="date"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="email"], input[type="month"], 
input[type="number"], input[type="password"], 
input[type="search"], input[type="tel"], 
input[type="text"], input[type="time"], 
input[type="url"], input[type="week"]{
    border-color: $black-off;
    border-width: 1px;
    padding: 0 $gutter;
    height: rem( 34 );
    line-height: rem( 34 );
    @include border-radius($radius);
    @include transition(all 0.3s ease-in-out);
    
    &:hover {
        border-color: $white-off;
    }
    
    &:focus{
        outline: 0;
        border-color: $blue;
        @include multiple-box-shadow( "inset 0 1px 1px rgba(0,0,0,0.075) , 0 0 8px rgba(102,175,233,0.6)" );
    }
}

::-webkit-input-placeholder {
   font-style: italic;
}
:-moz-placeholder {
   font-style: italic;  
}
::-moz-placeholder {
   font-style: italic;  
}
:-ms-input-placeholder {  
   font-style: italic; 
}

label {
    font-weight: $bold;
    padding-right: $gutter;
}

[class*="form-row-inline"] {
    @include flexbox();
    align-items: center;
    @include justify-content(center);
    flex-wrap: wrap;

    label {
        flex: 1;
    }

    input {
        flex: 1;
        
    }

    button {
        flex: 1;
        margin-left: $gutter;
    }
}

[class*="bt-icon"] {
    display: inline-block;
    @include box-sizing;
    outline: 0;
    @include transition(all 0.1s linear);
    cursor: pointer;
    @include border-radius($radius);
    background-color: transparent;
    color: $grey-medium;
    border-width: 1px;
    border-style: solid;
    position: relative;
    text-align: center;
    border-color: $grey-medium;
    @include transition(all 0.3s ease-in-out);

    .svg-inline--fa {
        height: 100%;
    }

    @at-root input + [class*="bt-icon"]{
        height: rem( 34 );
        line-height: rem( 34 );
        max-width: rem( 34 );
    }

    &:hover {
        color: $black-off;
        border-color: $black-off;
    }
    
    &:focus{
        outline: 0;
        color: $blue;
        border-color: $blue;
        @include multiple-box-shadow( "inset 0 1px 1px rgba(0,0,0,0.075) , 0 0 8px rgba(102,175,233,0.6)" );
    }
}


[class*="bt-icon-white"] {
    border-color: $grey-light;
    color: $grey-light;

    &:hover {
        color: $white;
        border-color: $white;
    }
}